import { Component, OnInit } from '@angular/core';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { interval } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataSharingService } from 'src/app/services/data-sharing.service';

@Component({
  selector: 'app-race-prediction',
  templateUrl: './race-prediction.component.html',
  styleUrls: ['./race-prediction.component.scss']
})

export class RacePredictionComponent implements OnInit {

  public getAllpreviousRaceListUrl = UrlConstants.getAllpreviousRaceList;
  public currentYearRaceInfoUrl = UrlConstants.currentYearRaceInfo;

  public racePredictions = UrlConstants.racePredictions;

  public raceList = [];

  public racesForcarosel1 = [];

  public racesForcarosel2 = [];

  public racesForcarosel3 = [];

  public racesForcarosel4 = [];

  public raceEntryAllUser = [];

  public tab1 = [];

  public tab2 = [];

  public tab3 = [];

  public tab4 = [];

  public tab5 = [];

  public tab6 = [];

  public tab7 = [];

  public selectedraceId;

  public raceResult: any = {};

  public PreLoader: boolean = false;
  public storedRaceName: string | null = '';

  public resultNotthere = false;
  public searchResultNotFound = false;
  public showSearchBox = true;

  public raceEntries = []
  public searchboxRaceId;
  public searchParam = "";
  public popover = 1;
  public endDateFormat;
  public upcomingRaceId: any;
  public previousRaceId: any;
  public upcomingRaceRound: any;

  public entryClosed: boolean = false;
  public activeRaceId: any;
  endDate;
  userProfileId = 0;
  count = 0;
  hideBlink = false;
  noRace: boolean = false;
  constructor(public _genericService: GenericAPIService, public encryptDecrypt: EncryptDecryptService,
    encryptDecryptService: EncryptDecryptService,
    private encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService, private dataSharingService: DataSharingService) {

    this.upcomingRaceId = +this.encryptDecrypt.getLocalStorage("upcomingRaceId");

    this.endDate = this.encryptDecrypt.getLocalStorage("raceBdClosingDateTime");

    this.getpreviousRaceList();

    //  this.getRacePrediction(1);

    this.endDateFormat = new Date(this.endDate);

    this.PreLoader = true;


    //var currentDateTime = new Date();
    var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.dataSharingService.currentTimeObs.subscribe(data => {
      currentDateTime = new Date(data);
    })
    interval(1000).pipe(
      map((x) => {
        currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
      })).subscribe((x) => {
      });

    if (currentDateTime > this.endDateFormat) {
      this.entryClosed = true;
    }
  }
  nextForMobile() {
    let nextRace;
    if (this.raceList.length != 0) {
      this.count++;
      this.count <= this.raceList.length - 1 ? nextRace = this.raceList[this.count].raceId : this.count = 0;
      nextRace = this.raceList[this.count].raceId;
      this.getRacePrediction(nextRace);
    }
  }
  previousForMobile() {
    var previousRace;
    if (this.raceList.length != 0) {
      this.count == 0 ? this.count = this.raceList.length - 1 : this.count--;
      previousRace = this.raceList[this.count].raceId;
      this.getRacePrediction(previousRace);
    }
  }
  getpreviousRaceList(): any {

    this._genericService.getAll(this.currentYearRaceInfoUrl).subscribe(response => {
      if (response.status.success == 'Success') {
        this.noRace = false;

        this.raceList = response.data;

        this.selectedraceId = this.raceList[((this.raceList).length) - 1].raceId;

        this.getRacePrediction(this.raceList[((this.raceList).length) - 1].raceId);

        var itemsPerSlide = 6;

        this.racesForcarosel1 = this.raceList.slice(0, itemsPerSlide)

        this.racesForcarosel2 = this.raceList.slice(1 * itemsPerSlide, 2 * itemsPerSlide)

        this.racesForcarosel3 = this.raceList.slice(2 * itemsPerSlide, 3 * itemsPerSlide)

        this.racesForcarosel4 = this.raceList.slice(3 * itemsPerSlide, this.raceList.length);

        var itemsForTab = 3

        this.tab1 = this.raceList.slice(0, itemsForTab)

        this.tab2 = this.raceList.slice(1 * itemsForTab, 2 * itemsForTab)

        this.tab3 = this.raceList.slice(2 * itemsForTab, 3 * itemsForTab)

        this.tab4 = this.raceList.slice(3 * itemsForTab, 4 * itemsForTab)

        this.tab5 = this.raceList.slice(4 * itemsForTab, 5 * itemsForTab)

        this.tab6 = this.raceList.slice(5 * itemsForTab, 6 * itemsForTab)

        this.tab7 = this.raceList.slice(6 * itemsForTab, this.raceList.length)


        const storedRaceName = this.encryptDecrypt.getLocalStorage("raceName");
        const raceNameAsString = `${storedRaceName}`;
        // if (storedRaceName) {
        //   const filterValue = this.raceList && this.raceList.filter((race) => {
        //     return race.raceId.toLowerCase().includes(this.upcomingRaceId);
        //   })
        //   if (filterValue && filterValue.length > 0) {
        //     const raceId = filterValue[0].raceId



        //   }

        // }

        let upcomingRaceRoundValue = sessionStorage.getItem("upcomingRaceRound");

        if (this.entryClosed) {

          this.activeRaceId = this.upcomingRaceId
          this.getRacePrediction(this.upcomingRaceId)
        } else {
          if (upcomingRaceRoundValue != "1") {
            this.activeRaceId = this.previousRaceId
            this.getRacePrediction(this.previousRaceId)
          }
        }



      }
      else {
        this.PreLoader = false;
        this.noRace = true;
      }

    }, err => { });

  }

  ngOnInit() {

    this.storedRaceName = this.encryptDecrypt.getLocalStorage("raceName") as string;
    this.previousRaceId = this.encryptDecrypt.getLocalStorage("previousRaceId");
  }

  onKey(event: any) {
    event.preventDefault();
    this.searchParam = event.target.value;
    if (event.keyCode == 13) {
      this.getRacePrediction(this.searchboxRaceId);
    }
  }
  onClickSearchPrediction(event) {
    this.searchParam = (<HTMLInputElement>document.getElementById("Search")).value;
    event.preventDefault();
    this.getRacePrediction(this.searchboxRaceId);
  }

  getRacePrediction(raceId) {
    this.searchboxRaceId = raceId;
    this.hideBlink = true;
    if (this.searchParam == "" && this.showSearchBox == true) {
      (<HTMLFormElement>document.getElementById("search")).reset();
    }
    if (raceId == this.upcomingRaceId && this.entryClosed == false) {
      this.raceEntries = [];
      this.resultNotthere = true;
      this.PreLoader = false;
      this.showSearchBox = false;
    } else {
      this.PreLoader = true;
      this.resultNotthere = false;
      this.searchResultNotFound = false;
      this.raceEntries = [];
      let url: any;
      if (this.searchParam == '') {
        url = this.racePredictions + '?raceId=' + raceId
      } else {
        url = this.racePredictions + '?raceId=' + raceId + '&searchTerm=' + this.searchParam
      }
      this._genericService.getAll(url).subscribe(response => {
        if (response.status.success == "Success") {

          this.raceEntries = response.data;
          this.PreLoader = false;

          this.raceEntries.sort(function (a, b) {
            var nameA = a.userName.toLowerCase(), nameB = b.userName.toLowerCase();
            if (nameA < nameB) //sort string ascending
              return -1
            if (nameA > nameB)
              return 1
            return 0 //default return value (no sorting)
          })
          if (this.raceEntries.length > 0) {
            this.showSearchBox = true;
            if (this.searchParam == "") {
              this.resultNotthere = false;
            } else if (this.raceEntries.length > 0) {
              this.searchResultNotFound = false;
            }
            else {
              this.searchResultNotFound = true;
            }
          } else {
            if (this.searchParam == "") {
              this.resultNotthere = true;
            } else if (this.raceEntries.length == 0) {
              this.searchResultNotFound = false;
            } else {
              this.searchResultNotFound = true;
            }
          }
          this.PreLoader = false;
          this.searchParam = "";
        } else {
          if (this.searchParam == "") {
            this.resultNotthere = true;
            this.showSearchBox = false;

          } else {
            this.searchResultNotFound = true;
            this.showSearchBox = true;
          }
          this.PreLoader = false;
          this.raceEntries = [];
          this.searchParam = "";
        }
      }, error => {
        this.raceEntries = [];
        this.resultNotthere = true;
        this.PreLoader = false;
        this.searchParam = "";
      })
    }

  }

  showIndividualProfile(userId) {
    this.userProfileId = userId;
    // Desktop
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '-' + $('.actual-page-view').height() + 'px');
      $(".actual-page-view").css('padding-bottom', '150px');
      $('.main-container').addClass('overflow-hidden');
      $('.single-user-detail-view').removeClass('d-none');
      setTimeout(function () {
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').addClass('d-none');
      $('.single-user-detail-view').removeClass('d-none');
    }
  }

  // Hide Single User Detail View
  closeSingleUserDetailView() {
    // this.userProfileId=0;
    // Desktop
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '0px');
      $(".actual-page-view").css('padding-bottom', '0px');
      $('.main-container').addClass('overflow-hidden');
      setTimeout(function () {
        $('.single-user-detail-view').addClass('d-none');
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').removeClass('d-none');
      $('.single-user-detail-view').addClass('d-none');
    }

  }
}

