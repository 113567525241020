<div class="row h-100 mx-0 race-prediction actual-page-view">

  <div class="col-lg-12 h-100">
    <div class="card shadow-sm rounded">
      <h4 class="card-title bg-rose">Race Prediction</h4>
      <div class="card-body" *ngIf="!noRace">
        <!-- Desktop -->
        <div id="racePredictionSlider" class="carousel slide d-none d-lg-block" data-ride="carousel"
          data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <!-- <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel1">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <div>{{race.name}}</div>
                        </a>
                        </li> -->
                        <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel1">
                          <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                            (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                            [class.active]="race.raceId === activeRaceId">
                            <img src="{{race.circuits.circuitActive}}" alt="circuit" />
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 2 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel2">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-home" aria-selected="true"
                        [class.active]="race.raceId == activeRaceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 3 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel3">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-home" aria-selected="true"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 4 -->
                    <li class="nav-item col-sm-2 text-center" *ngFor="let race of racesForcarosel4">
                      <a class="nav-link" id="pills-home-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-home" aria-selected="true"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#racePredictionSlider" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#racePredictionSlider" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- Tab -->
        <div id="racePredictionSlider1" class="carousel slide d-none d-sm-block d-lg-none" data-ride="carousel"
          data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab1">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab2">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab3">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab4">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab5">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab6">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>
            <div class="carousel-item">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-sm-4 text-center" *ngFor="let race of tab7">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                      
                        <div>{{race.name}}</div>
                      </a>
                    </li>

                  </ul>
                </div>
              </div>
            </div>

          </div>
          <a class="carousel-control-prev" href="#racePredictionSlider1" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#racePredictionSlider1" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
        <!-- Mobile -->
        <div id="racePredictionSlider2" class="carousel slide d-block d-sm-none" data-ride="carousel"
          data-interval="false">
          <div class="carousel-inner">
            <div class="carousel-item" *ngFor="let race of raceList;let i=index" [ngClass]="{'active': i ==0}">
              <div class="row align-items-center justify-content-center">
                <div class="col-lg-12">
                  <ul class="nav nav-pills row align-items-center" id="pills-tab" role="tablist">
                    <!-- 1 -->
                    <li class="nav-item col-12 text-center">
                      <a class="nav-link" id="pills-profile-tab" data-toggle="pill" href="#location-1" role="tab"
                        (click)="getRacePrediction(race.raceId)" aria-controls="pills-profile" aria-selected="false"
                        [class.active]="race.raceId == selectedraceId">
                        <img src={{race.circuits.circuitActive}} alt="circuit" />
                  
                        <div>{{race.name}}</div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <a class="carousel-control-prev" href="#racePredictionSlider2" role="button" data-slide="prev"
            (click)="previousForMobile()">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a class="carousel-control-next" href="#racePredictionSlider2" role="button" data-slide="next"
            (click)="nextForMobile()">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>

        <!-- Search box *ngIf="showSearchBox==true" -->
        <form onkeypress="return event.keyCode != 13" id="search">
          <div *ngIf="showSearchBox==true" class="form-group search-box">
            <div class="input-group">
              <input type="text" class="form-control" [ngClass]="{'input-blink':hideBlink}" (click)="hideBlink=false"
                (keyup)="onKey($event)" id="Search" placeholder="Search">
              <div class="input-group-append">
                <span class="input-group-text" id="inputGroupPrepend"><i class="fa fa-search"
                    (click)="onClickSearchPrediction($event)"></i></span>
              </div>
            </div>
          </div>
        </form>
        <!-- New UI for Race Prediction *ngIf="raceEntries.length>0"-->
        <div class="tab-content" id="pills-tabContent" *ngIf="raceEntries.length>0">
          <!-- Location 1 -->
          <div class="tab-pane fade show active" id="location-1" role="tabpanel" aria-labelledby="pills-home-tab">

            <div class="card pb-0 mb-4">
              <div class="card-body p-0 overflow-hidden">
                <div class="table-responsive race-prediction-table">
                  <table class="table table-dark border-bottom">
                    <thead>
                      <tr>
                        <th rowspan="2" class="text-center border-left border-right headcol"> Competitors</th>
                        <th colspan="6" class="text-center border-right bb-0">Drivers</th>
                        <th colspan="3" class="text-center border-right bb-0">Sprint Entry</th>
                        <th colspan="3" class="text-center border-right bb-0">Constructors</th>
                        <th rowspan="2" class="text-center border-right"> MPG</th>
                        <th rowspan="2" class="text-center border-right"> FL</th>
                        <th rowspan="2" class="text-center border-right"> PP</th>
                      </tr>
                      <tr>
                        <th class="headcol border-0 d-block d-xl-none"></th>
                        <th class="bt-0">1st</th>
                        <th class="bt-0">2nd</th>
                        <th class="bt-0">3rd</th>
                        <th class="bt-0">4th</th>
                        <th class="bt-0">5th</th>
                        <th class="bt-0 border-right">6th</th>
                        <th class="bt-0">1st</th>
                        <th class="bt-0">2nd</th>
                        <th class="bt-0 border-right">3rd</th>
                        <th class="bt-0">1st</th>
                        <th class="bt-0">2nd</th>
                        <th class="bt-0 border-right">3rd</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let raceEntry of raceEntries">
                        <td class="border-left border-right text-center cursor-pointer headcol"
                          (click)="showIndividualProfile(raceEntry.userId)">
                          <div>{{raceEntry.userName}}</div>
                        </td>

                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[0].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[0].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[0].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[0].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[0].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent0" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[0].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[0].drivers.surName}}</div>
                        </td>
                        <!-- //for Empty Drivers -->
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length==0" class="center border-right">
                          -
                        </td>




                        <!-- //for Empty Constructors -->
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length==0" class="center ">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length==0" class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length==0" class="center border-right">
                          -
                        </td>
                        <!-- //For Lap Details -->
                        <td *ngIf="raceEntry.raceEntryLapDetail==null" class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryLapDetail==null" class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryLapDetail==null" class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent1>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[1].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[1].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[1].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent1"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[1].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[1].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent1" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[1].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[1].drivers.surName}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent2>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[2].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[2].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[2].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent2"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[2].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[2].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent2" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[2].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[2].drivers.surName}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent3>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[3].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[3].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[3].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent3"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[3].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[3].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent3" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[3].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[3].drivers.surName}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent4>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[4].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[4].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[4].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent4"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[4].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[4].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent4" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[4].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[4].drivers.surName}}</div>
                        </td>
                        <td class="border-right" *ngIf="raceEntry.raceEntryDriverTRX.length>0">
                          <ng-template #popContent5>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryDriverTRX[5].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryDriverTRX[5].drivers.foreName+'
                              '+raceEntry.raceEntryDriverTRX[5].drivers.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContent5"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryDriverTRX[5].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[5].drivers.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContent5" placement="top"
                            [autoClose]="true">{{raceEntry.raceEntryDriverTRX[5].drivers.foreName+'
                            '+raceEntry.raceEntryDriverTRX[5].drivers.surName}}</div>
                        </td>

                        <!-- raceEntrySprintTrx -->

                        <!-- race entry -->
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length==0"
                          class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length==0"
                          class="center">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length==0"
                          class="center border-right">
                          -
                        </td>
                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntrySprintTrx[0].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[0].drivers.foreName+'
                              '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                            </div>
                          </ng-template>

                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>

                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>
                        </td>

                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntrySprintTrx[1].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[1].drivers.foreName+'
                              '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                            </div>
                          </ng-template>

                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>

                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>
                        </td>

                        <td class="border-right"
                          *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntrySprintTrx[2].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[2].drivers.foreName+'
                              '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                            </div>
                          </ng-template>

                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>

                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+'
                            '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>
                        </td>



                        <!-- <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img src={{raceEntry.raceEntrySprintTrx[0].drivers.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntrySprintTrx[0].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                            </div>
                          </ng-template>
                           
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0" placement="right"
                            triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>
                           
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0" placement="top"
                            [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[0].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[0].drivers.surName}}
                          </div>
                        </td>

                        <td *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC1>
                            <div class="user-name-pic"><span class="user-pic"><img src={{raceEntry.raceEntrySprintTrx[1].drivers.driverPic}}
                                  class="img-fluid" /></span>
                                  {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                            </div>
                          </ng-template>
                           
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC1" placement="right"
                            triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+' '+aceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>
                          
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC1" placement="top"
                            [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[1].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[1].drivers.surName}}
                          </div>
                        </td>

                        <td class="border-right" *ngIf="raceEntry.raceEntrySprintTrx && raceEntry.raceEntrySprintTrx.length>0">
                          <ng-template #popContentC2>
                            <div class="user-name-pic"><span class="user-pic"><img src={{raceEntry.raceEntrySprintTrx[2].drivers.driverPic}}
                                  class="img-fluid" /></span>
                                  {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                            </div>
                          </ng-template>
                          
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC2" placement="right"
                            triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+' '+aceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>
                          
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC2" placement="top"
                            [autoClose]="true">
                            {{raceEntry.raceEntrySprintTrx[2].drivers.foreName+' '+raceEntry.raceEntrySprintTrx[2].drivers.surName}}
                          </div>
                        </td> -->


                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length>0">
                          <ng-template #popContentC0>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryConstructorTRX[0].constructors.constructorPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryConstructorTRX[0].constructors.name}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC0"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryConstructorTRX[0].constructors.name}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC0"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryConstructorTRX[0].constructors.name}}</div>
                        </td>
                        <td *ngIf="raceEntry.raceEntryConstructorTRX.length>0">
                          <ng-template #popContentC1>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryConstructorTRX[1].constructors.constructorPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryConstructorTRX[1].constructors.name}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC1"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryConstructorTRX[1].constructors.name}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC1"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryConstructorTRX[1].constructors.name}}</div>
                        </td>
                        <td class="border-right" *ngIf="raceEntry.raceEntryConstructorTRX.length>0">
                          <ng-template #popContentC2>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryConstructorTRX[2].constructors.constructorPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryConstructorTRX[2].constructors.name}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentC2"
                            placement="right" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryConstructorTRX[2].constructors.name}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentC2"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryConstructorTRX[2].constructors.name}}</div>
                        </td>


                        <td class="border-right text-center" *ngIf="raceEntry.raceEntryLapDetail!=null">
                          <ng-template #popContentMPG>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName+'
                              '+raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}
                            </div>
                          </ng-template>
                          <!-- Deskop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentMPG"
                            placement="left" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentMPG"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.mostPlacesGainedDriver.surName}}</div>
                        </td>

                        <td class="border-right text-center" *ngIf="raceEntry.raceEntryLapDetail!=null">
                          <ng-template #popContentFL>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryLapDetail.fastestLapDriver.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryLapDetail.fastestLapDriver.foreName+'
                              '+raceEntry.raceEntryLapDetail.fastestLapDriver.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentFL"
                            placement="left" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryLapDetail.fastestLapDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.fastestLapDriver.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentFL"
                            placement="top" [autoClose]="true">
                            {{raceEntry.raceEntryLapDetail.fastestLapDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.fastestLapDriver.surName}}</div>
                        </td>
                        <td class="border-right text-center" *ngIf="raceEntry.raceEntryLapDetail!=null">
                          <ng-template #popContentPP>
                            <div class="user-name-pic"><span class="user-pic"><img
                                  src={{raceEntry.raceEntryLapDetail.polePositionDriver.driverPic}}
                                  class="img-fluid" /></span>{{raceEntry.raceEntryLapDetail.polePositionDriver.foreName+'
                              '+raceEntry.raceEntryLapDetail.polePositionDriver.surName}}
                            </div>
                          </ng-template>
                          <!-- Desktop -->
                          <div class="text-ellipsis d-none d-lg-inline-block" [ngbPopover]="popContentPP"
                            placement="left" triggers="mouseenter:mouseleave">
                            {{raceEntry.raceEntryLapDetail.polePositionDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.polePositionDriver.surName}}</div>
                          <!-- Mobile -->
                          <div class="text-ellipsis d-inline-block d-lg-none" [ngbPopover]="popContentPP"
                            placement="left" [autoClose]="true">
                            {{raceEntry.raceEntryLapDetail.polePositionDriver.foreName+'
                            '+raceEntry.raceEntryLapDetail.polePositionDriver.surName}}</div>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-content text-center" id="pills-tabContent"
          *ngIf="searchResultNotFound==true && resultNotthere==false">
          <span>No records found</span>
        </div>

        <div class="tab-content" id="pills-tabContent" *ngIf="resultNotthere==true && searchResultNotFound==false">
          <div class="row">
            <div class="col-sm-12 text-center">
              <span>Race prediction not yet updated</span>
            </div>
          </div>
        </div>
        <div class="box-loader" *ngIf="PreLoader">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
      <!-- no race for a year -->
      <div class="col-lg-12 p-2 race-prediction " *ngIf="noRace">
        <div class="row prediction align-items-center justify-content-center h-100" *ngIf="noRace">
          <div class="col-lg-12">
            <p class="border-0 m-0">Currently, there are no race entries, stay tuned for updates...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Single User Detail view -->
<div class="row h-100 m-0 single-user-detail-view" *ngIf="userProfileId!=undefined && userProfileId!=0">
  <div class="col-lg-12 h-100">
    <div class="card h-auto mb-4 r down-arw" (click)="closeSingleUserDetailView()">
      <div class="card-body p-3 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" width="34.848" height="18.292" viewBox="0 0 34.848 18.292">
          <path class="a" d="M7028.1,184.362,7044.15,199.5l16.76-15.138" transform="translate(-7027.068 -183.249)" />
        </svg>
      </div>
    </div>
    <app-single-user-detail [individualUserDetail]="{'userId':userProfileId,'isindividual':true}">
    </app-single-user-detail>
  </div>
</div>