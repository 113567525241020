import { Component, OnInit } from "@angular/core";
import { GenericAPIService } from "src/app/services/generic-api.service";
import { UrlConstants } from "src/app/constants/url-constants";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { NotificationConstants } from "src/app/constants/notification-constants";
declare var $: any;
import { IMyDpOptions } from "mydatepicker";

@Component({
  selector: "app-driver",
  templateUrl: "./driver.component.html",
  styleUrls: ["./driver.component.scss"],
})
export class DriverComponent implements OnInit {
  public myDatePickerOptions: IMyDpOptions;
  driverMDlistUrl = UrlConstants.driverMDList;
  constructorMDlistUrl = UrlConstants.constructorMDList;

  driverSaveMD = UrlConstants.saveDriverMD;
  deleteDriverMDUrl = UrlConstants.deleteDriverMD;
  driverList: any = [];
  constructorList: any = [];
  noRecord: boolean = false;
  driverForm;
  isEdit: boolean = false;
  driverImage: File;
  public driverSrc;
  public uploadDriverPic = false;
  failureMsg;
  reqPayLoad = {};
  editObj: any = {};
  deleteObj = {};
  preloader: boolean = false;
  deletePayload = {};
  failureUrl = "../../../../assets/img/failure.gif";
  failureGif;
  slideInRight;
  slideOutRight;
  constructor(public genericService: GenericAPIService) {
    this.myDatePickerOptions = {
      dateFormat: "yyyy-mm-dd",
      showClearDateBtn: false,
      editableDateField: false,
      openSelectorOnInputClick: true,
    };
  }

  ngOnInit() {
    this.getDriverMDList();
    this.getConstructorMDList();
    this.createForm();
  }
  resetFields() {
    this.uploadDriverPic = false;
    this.driverImage = null;
    this.driverSrc = null;
    this.isEdit = false;
    this.driverForm.reset();
  }
  createForm() {
    this.driverForm = new FormGroup({
      foreName: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^\s].+[^\s]$/),
      ]),
      surName: new FormControl("", [
        Validators.required,
        Validators.pattern(/^[^\s].+[^\s]$/),
      ]),
      constructor: new FormControl("", [Validators.required]), 
      isActive: new FormControl(true),
      joinedDate: new FormControl("", [Validators.required]),
      constructorJoinedDate: new FormControl("", [Validators.required]),
    });
  }
  readURLForUser(event): void {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      if (this.checkValidOrNotForUser(file) == true) {
        this.driverImage = file;
        this.uploadDriverPic = true;
        const reader = new FileReader();
        reader.onload = (e) => (this.driverSrc = reader.result);
        reader.readAsDataURL(file);
      }
    }
  }
  searchDriver(event) {
    var searchKey = event.target.value;
    this.genericService
      .getAll(this.driverMDlistUrl + "?search=" + searchKey)
      .subscribe(
        (response) => {
          if ((response.status.success = "Success")) {
            this.driverList = response.data;
            response.totalResult < 1
              ? (this.noRecord = true)
              : (this.noRecord = false);
          } else {
            this.noRecord = true;
          }
        },
        (error) => {}
      );
  }
  checkValidOrNotForUser(file) {
    var valid: boolean = true;
    this.failureGif = NotificationConstants.getLinkPicture(this.failureUrl);
    if (file.size > 300000) {
      valid = false;
      this.failureMsg = "File size should not Exceeds 300KB";
    }
    var fileExtension = file.name.substr(
      file.name.lastIndexOf(".") + 1,
      file.name.length
    );
    if (
      fileExtension != "jpeg" &&
      fileExtension != "png" &&
      fileExtension != "jpg"
    ) {
      valid = false;
      this.failureMsg = NotificationConstants.correctFileTypeMsg;
    }
    setTimeout(() => {
      this.slideOutRight = true;
      this.slideInRight = false;
    }, 3000);
    setTimeout(() => {
      this.slideOutRight = false;
      this.slideInRight = true;
      this.failureMsg = undefined;
    }, 6000);
    if (valid == true) {
      this.failureMsg = undefined;
    }
    return valid;
  }

  cancelDelete() {
    $("#delete").modal("hide");
    this.deleteObj = {};
  }

  delete() {
    this.preloader = true;
    if (this.deleteObj != null) {
      this.deletePayload["driverMasterId"] = this.deleteObj["driverMasterId"];
      this.deletePayload["isActive"] = false;
      this.genericService
        .post(this.deleteDriverMDUrl, this.deletePayload)
        .subscribe(
          (response) => {
            this.deleteObj = {};
            this.deletePayload = {};
            if (response.status.success == "Success") {
              $("#delete").modal("hide");
              this.getDriverMDList();
            }
            this.preloader = false;
          },
          (error) => {
            this.preloader = false;
          }
        );
    }
  }

  getEditDriverDetails(driver) {
    
    this.resetFields();
    this.editObj = driver;
    var joinedDateObj;
    
  let constructorName = '';
  try {
    constructorName = driver && driver.constructorMaster && driver.constructorMaster.constructorMasterId 
      ? driver.constructorMaster.constructorMasterId 
      : '';
  } catch (error) {
    console.error('Error getting constructor name:', error);
    constructorName = '';
  }
    
    if (this.editObj.joinedDate != null) {
      var subscriptionDate = new Date(this.editObj.joinedDate);
      joinedDateObj = {
        date: {
          month: subscriptionDate.getMonth() + 1,
          day: subscriptionDate.getDate(),
          year: subscriptionDate.getFullYear(),
        },
      };
    }
    var constructorJoinedDate;
    if (this.editObj.constructorJoinedDate != null) {
      var subscriptionDate = new Date(this.editObj.constructorJoinedDate);
      constructorJoinedDate = {
        date: {
          month: subscriptionDate.getMonth() + 1,
          day: subscriptionDate.getDate(),
          year: subscriptionDate.getFullYear(),
        },
      };
    }
    
    this.driverForm.controls["foreName"].setValue(this.editObj["foreName"]);
    this.driverForm.controls["surName"].setValue(this.editObj["surName"]);
    this.driverForm.controls["joinedDate"].setValue(joinedDateObj);
    this.driverForm.controls["constructorJoinedDate"].setValue(
      constructorJoinedDate
    );
    this.driverForm.controls["constructor"].setValue(constructorName);
    if (this.editObj["driverPic"] != null) {
      this.uploadDriverPic = true;
      this.driverSrc = this.editObj["driverPic"];
    }
    this.isEdit = true;
    $("#addDriver").modal("show");
  }
  getDriverMDList() {
    this.preloader = true;
    this.genericService.getAll(this.driverMDlistUrl).subscribe(
      (response) => {
        if ((response.status.success = "Success")) {
          this.driverList = response.data;
          
          this.preloader = false;
          response.totalResult < 1
            ? (this.noRecord = true)
            : (this.noRecord = false);
        } else {
          this.noRecord = true;
          this.preloader = false;
        }
      },
      (error) => {
        this.preloader = false;
      }
    );
  }


  getConstructorMDList() {
    this.genericService.getAll(this.constructorMDlistUrl).subscribe(response => {
      if (response.status.success = "Success") {
        this.constructorList = response.data;
        response.totalResult < 1 ? this.noRecord = true : this.noRecord = false;
      }
      else {
        this.noRecord = true;
      }
      this.preloader=false;
    }, error => {
      this.preloader=false;

    })

  }

  changeDateFormat(date) {
    date.month =
      date.month.toString().length == 1 ? "0" + date.month : date.month;
    date.day = date.day.toString().length == 1 ? "0" + date.day : date.day;
    return date.year + "-" + date.month + "-" + date.day;
  }
  payloadFormation() {
    this.preloader = false;
    this.reqPayLoad = {
      foreName: this.driverForm.controls["foreName"].value,
      surName: this.driverForm.controls["surName"].value,
      constructorMasterId: this.driverForm.controls["constructor"].value,
      joinedDate: this.changeDateFormat(
        this.driverForm.controls["joinedDate"].value.date
      ),
      constructorJoinedDate: this.changeDateFormat(
        this.driverForm.controls["constructorJoinedDate"].value.date
      ),
      isActive: true,
    };
    if (this.isEdit == true) {
      this.reqPayLoad["driverMasterId"] = this.editObj["driverMasterId"];
    }
    
    this.save();
  }

  save() {
    if (this.driverSrc != null || this.driverSrc != undefined) {
      this.preloader = true;
      let formdata: FormData = new FormData();
      let eee = JSON.stringify(this.reqPayLoad);
      formdata.append("drivers", eee);
      this.driverImage != null || this.driverImage != undefined
        ? formdata.append("driverProfileImage", this.driverImage)
        : formdata.append("driverImage", null);
      this.genericService.imagePost(this.driverSaveMD, formdata).subscribe(
        (response) => {
          if (
            response.status.success == "Success" ||
            response.status.success == "success"
          ) {
            this.getDriverMDList();
            $("#addDriver").modal("hide");
          }
          this.preloader = false;
        },
        (error) => {
          this.preloader = false;
          $("#addDriver").modal("hide");
        }
      );
    } else {
      this.failureGif = NotificationConstants.getLinkPicture(this.failureUrl);
      this.failureMsg = NotificationConstants.mandotoryMsg;
      setTimeout(() => {
        this.slideOutRight = true;
        this.slideInRight = false;
      }, 3000);
      setTimeout(() => {
        this.slideOutRight = false;
        this.slideInRight = true;
        this.failureMsg = undefined;
      }, 6000);
    }
  }
  // public getLinkPicture(url) {
  //   var timeStamp = (new Date()).getTime();
  //   return url + '?' + timeStamp;
  // }
}
