import { Component, OnInit } from '@angular/core';
import { UrlConstants } from 'src/app/constants/url-constants';
import { GenericAPIService } from 'src/app/services/generic-api.service';
import { EncryptDecryptService } from 'src/app/services/encrypt-decrypt.service';
import { checkAndUpdateBinding } from '@angular/core/src/view/util';
import { EncryptDecryptLocalStorageService } from 'src/app/services/encrypt-decrypt-localStorage.service';
import { DataSharingService } from 'src/app/services/data-sharing.service';
import { interval } from 'rxjs';
import { map, count } from 'rxjs/operators';
declare var $: any;
@Component({
  selector: 'app-ladder',
  templateUrl: './ladder.component.html',
  styleUrls: ['./ladder.component.scss']
})
export class LadderComponent implements OnInit {

  public playerLadderUrl = UrlConstants.playerLadderUrl;
  public getChallengesUrl = UrlConstants.ladderHistoryUrl;
  public saveChallengeUrl = UrlConstants.saveChallenge;
  public getPreChallengeInfoUrl = UrlConstants.getPreChallengeInfo;
  public raceInfoUrl = UrlConstants.raceInfo;
  public challengesForUser = UrlConstants.challengeByurl;
  public revokeChallengeUrl = UrlConstants.revokeChallengeUrl;
  public currentYearRaceInfo = UrlConstants.racesUrl;
  public ladderChallengeForRaceUrl = '/get/all/ladderChallenge/Race';

  public preChallengDetails = {
    "noOfChallengesDoneInPart1": 0,
    "totalNoOfracesInSeason": 0,
    "isChallengedForThisrace": false,
    "noOfChallengesForDoneInThisSeason": 0
  }
  public users = [];
  public selectedUser: any = {};
  public raceName: any;
  public challenges = [];
  public isPart1 = false;
  public alreadyChallenged = false;
  public previousRaceId = 0;
  public upcomingRaceId;
  public isChallengedForThisrace = false;
  public isRaceEntryDone = false;
  PreLoader = false;
  PreLoader1 = false;
  PreLoader2 = false;
  PreLoaderForSave = false;
  // successMessage: any;
  // failureMessage: any;
  showMessage = false;
  userId;
  noUser: boolean = false;
  sumOfChallenges: any = 0;
  noOfChallengesLeft = 0;
  isFirstRace: boolean = false;
  challengesForUserList = [];
  PreLoader3 = false;
  showMessage1: any;
  startDate: any;
  endDate: any;
  startDateFormat: Date;
  endDateFormat: Date;
  userProfileId = 0;
  revokeUserId = 0;
  revokeChallengeId;
  challengedBy: boolean = false;
  challenged: boolean = true;
  races: any = [];
  challengeForRace: any = [];
  currentDateTime;
  previousRaceChallenge = [];
  sortByRace: boolean = false;
  upcomingRaceRound;
  public latestRaceDetailsUrl = UrlConstants.latestRaceDetail;
  noRace: boolean = false;
  constructor(public _genericService: GenericAPIService, public encryptDecryptLocalStorageService: EncryptDecryptLocalStorageService,
    public encryptDecryptService: EncryptDecryptService, public dataSharingService: DataSharingService) {
    this.raceName = this.encryptDecryptService.getLocalStorage("raceName");
    this.previousRaceId = +this.encryptDecryptService.getLocalStorage("previousRaceId");
    this.upcomingRaceId = +this.encryptDecryptService.getLocalStorage("upcomingRaceId");
    this.userId = this.encryptDecryptService.getLocalStorage("userId");
    this.upcomingRaceRound = this.encryptDecryptLocalStorageService.getLocalStorage('upcomingRaceRound');
    this.PreLoader2 = true;
    console.log(this.upcomingRaceRound)
  }

  getLatestRaceDetail() {
    if (this.upcomingRaceId != 0) {
      this.noRace = false;
      this._genericService.getAll(this.latestRaceDetailsUrl + '?raceId=' + this.upcomingRaceId + '&userId=' + this.userId).subscribe(response => {
        if (response.status.success == "Success") {
          this.encryptDecryptService.setToLocalStorage("raceName", response.data.raceName)
          this.raceName = this.encryptDecryptService.getLocalStorage("raceName");
          this.currentDateTime = new Date(response.data.todayDateTime);

          if (this.upcomingRaceRound != 1) {
            this.getPreChallengedata();
          }

          this.getChallangesByUserAndSeason();
          this.getChallangesForUserAndSeason();
        }
      })
    }
    else {
      this.noRace = true;
    }
  }

  ngOnInit() {
    this.getLatestRaceDetail();
    this.challegeForRace();
    this.getRacesInfo();
  }
  challegeForRace() {
    //  this.PreLoader2=true;
    this._genericService.getAll(this.ladderChallengeForRaceUrl + "?raceId=" + 0 + "&userId=" + this.userId).subscribe(response => {
      if (response.status.success == "Success") {
        this.previousRaceChallenge = response.data;
      }
      else {
        this.previousRaceChallenge = [];
      }
      this.PreLoader2 = false;

    }, error => {
      this.PreLoader2 = false;
    })
  }
  filterForRace(event) {
    this.PreLoader2 = true;
    this._genericService.getAll(this.ladderChallengeForRaceUrl + "?raceId=" + event).subscribe(response => {
      if (response.status.success == "Success") {
        this.challengeForRace = response.data;
      }
      else {
        this.challengeForRace = [];
      }
      this.PreLoader2 = false;

    }, error => {
      this.PreLoader2 = false;
    })

  }
  getRacesInfo() {
    this._genericService.getAll(this.currentYearRaceInfo).subscribe(response => {
      if (response.status.success == 'Success') {
        this.races = response.data;
        if (this.races.length > 0) {
          this.filterForRace(this.races[0].RaceResult.raceId);
        }
      }

    })
  }
  getPreChallengedata() {
    this.PreLoader = true
    this._genericService.getAll(this.getPreChallengeInfoUrl + "?userId=" + this.userId + "&raceId=" + this.upcomingRaceId).subscribe(response => {
      if (response.status.success == "Success") {

        this.preChallengDetails = response.data;
        if (this.preChallengDetails.noOfChallengesForDoneInThisSeason != null) {
          this.noOfChallengesLeft = 13 - this.preChallengDetails.noOfChallengesForDoneInThisSeason
        }
        this.isRaceEntryDone = response.data.isRaceEntryDone;
        this.getPlayerLeaderBoardList();
      }
      else {
        this.PreLoader = false
      }
    }, error => {
      this.PreLoader = false
    })
  }

  getChallangesByUserAndSeason() {
    this.PreLoader1 = true
    this._genericService.getAll(this.getChallengesUrl + "?userId=" + this.userId).subscribe(response => {
      if (response.status.success == "Success") {
        this.challenges = response.data;
        //to get the sum of challege
        this.sumOfChallenges = 0;
        this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
        this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
        var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
        this.dataSharingService.currentTimeObs.subscribe(data => {
          currentDateTime = new Date(data);
        })
        interval(1000).pipe(
          map((x) => {
            currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
          })).subscribe((x) => {
          });
        this.startDateFormat = new Date(this.startDate);
        this.endDateFormat = new Date(this.endDate);
        //get pre data...
        const todayDateTime = new Date()
        for (let challege of this.challenges) {
          if (challege.raceName == this.raceName) {
            if ((todayDateTime >= this.startDateFormat) && (todayDateTime <= this.endDateFormat)) {
              this.revokeUserId = challege.challengedUserId;
              this.revokeChallengeId = challege.challengeId;
            }
          }
        }
        //  this.sumOfChallenges = this.challenges.reduce((sum, { points }) => sum + (points), 0);
        for (let challege of this.challenges) {
          this.sumOfChallenges = this.sumOfChallenges + (+challege.points)
        }

        if (Number.isNaN(this.sumOfChallenges)) {
          this.sumOfChallenges = 0
        }
        if (response.data == null) {
          this.showMessage = true;
        }
        else {
          this.showMessage = false;
        }
        this.PreLoader1 = false
      }
      else {
        this.challenges = [];
        this.showMessage = true;
        this.PreLoader1 = false
      }

    }, error => {
      this.challenges = [];
      this.PreLoader1 = false
    })
  }
  getPlayerLeaderBoardList() {
    this._genericService.getAll(this.playerLadderUrl + "?raceId=" + this.previousRaceId).subscribe(response => {
      if (response.status.success == "Success") {
        this.users = response.data;
        // //To make logged In user should be down when compare with same marks persons start
        // var userId = this.encryptDecryptService.getLocalStorage("userId");
        // var userIndex = this.users.findIndex(x => x.userId == userId);
        // var loggedInUser = this.users[userIndex];
        // var loggedInUserMarks;
        // var sameMarksUsers = []
        // var indexOfLastPerson;
        // if (loggedInUser != undefined) {
        //   loggedInUserMarks = loggedInUser.totalPoints
        //   sameMarksUsers = this.users.filter(user=>user.totalPoints==loggedInUserMarks);
        //   if(sameMarksUsers.length>1){
        //   var  lastPersonWithSameMarks=sameMarksUsers[sameMarksUsers.length-1];
        //   indexOfLastPerson = this.users.findIndex(x => x.userId == lastPersonWithSameMarks.userId);
        //   this.users[indexOfLastPerson]=loggedInUser;
        //   this.users[userIndex]=lastPersonWithSameMarks;
        //   }
        // }
        //  //To make logged In user should be down when compare with same marks persons end

        // var count = 0;
        // for (let user of this.users) {
        //   user.isDisabled = this.isDisabled(count, user);
        //   count++;
        // }
        for (let user of this.users) {
          user.isDisabled = true;
        }
        this.checkChallengeCondition();
        if (this.users[0].rank == 0) {
          this.isFirstRace = true;
        }
        var currentDateTime = new Date();

        this.PreLoader = false;
      }
      else {
        this.PreLoader = false;
        this.noUser = true;
      }

    }, error => {
      this.PreLoader = false;
      this.noUser = true;

    })
  }

  checkChallengeCondition() {
    //find login user index and make all above to enble...
    this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
    this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
    var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.dataSharingService.currentTimeObs.subscribe(data => {
      currentDateTime = new Date(data);
    })
    interval(1000).pipe(
      map((x) => {
        currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
      })).subscribe((x) => {
      });
    this.startDateFormat = new Date(this.startDate);
    this.endDateFormat = new Date(this.endDate);
    //get pre data...
    var userId = this.encryptDecryptService.getLocalStorage("userId");
    var userIndex = this.users.findIndex(x => x.userId == userId);
    var userTeamName = this.encryptDecryptService.getLocalStorage("teamName");
    var loggedInUser = this.users[userIndex];
    console.log(loggedInUser)
    if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
      if (loggedInUser != undefined) {
        if (this.alreadyChallenged == false) {
          //Make all above enable start...........
          for (let user of this.users) {
            if (user.rank < loggedInUser.rank) {
              user.isDisabled = false;
            }
          }
      //Make all above enable end...........

          //same team members to be disable start....
          for (let user of this.users) {
            if (user.teamId == loggedInUser.teamId) {
              user.isDisabled = true;
            }
          }
          //same team members to be disable end....

          //count how many enables are there...make only two enable start....
          var aboveUsers = [];
          for (let user of this.users) {
            if (user.rank < loggedInUser.rank) {
              aboveUsers.push(user);
            }
          }
          aboveUsers.reverse();
          var count = 0;
          for (let aboveUser of aboveUsers) {
            if (aboveUser.isDisabled == false) {
              count = count + 1;
            }
            if (count > 2) {
              aboveUser.isDisabled = true;
            }
          }
          console.log(aboveUsers);

          //count how many enables are there...make only two enable end....

          // 2.Each player will get a maximum of 10 challenges for the entire year. 
          var noOfChallengesForDoneInThisSeason = this.preChallengDetails.noOfChallengesForDoneInThisSeason;
          if (noOfChallengesForDoneInThisSeason >= 13) {
            for (let user of this.users) {
              user.isDisabled = true;
            }
          }




          //4.A player can make only 1 Challenge per race weekend.
          if (this.preChallengDetails.isChallengedForThisrace == true) {
            for (let user of this.users) {
              user.isDisabled = true;
            }
          }


        }
      }
    }


  }

  isDisabled(index, rowUser) {
    var isButtonDisable: boolean = true;
    this.startDate = this.encryptDecryptService.getLocalStorage("raceBdStartingDateTime");
    this.endDate = this.encryptDecryptService.getLocalStorage("raceBdClosingDateTime");
    var currentDateTime = new Date(this.encryptDecryptLocalStorageService.getLocalStorage("todayDate"));
    this.dataSharingService.currentTimeObs.subscribe(data => {
      currentDateTime = new Date(data);
    })
    interval(1000).pipe(
      map((x) => {
        currentDateTime.setSeconds(currentDateTime.getSeconds() + 1);
      })).subscribe((x) => {
      });
    this.startDateFormat = new Date(this.startDate);
    this.endDateFormat = new Date(this.endDate);
    //get pre data...
    var userId = this.encryptDecryptService.getLocalStorage("userId");
    var userIndex = this.users.findIndex(x => x.userId == userId);
    var userTeamName = this.encryptDecryptService.getLocalStorage("teamName");
    var loggedInUser = this.users[userIndex];
    if ((currentDateTime >= this.startDateFormat) && (currentDateTime <= this.endDateFormat)) {
      if (loggedInUser != undefined) {
        // if (loggedInUser.totalPoints > 0) {
        if (this.alreadyChallenged == false) {
          // //he canot  challenge the person with same marks but if 1 person above is same mark need to give chance for above two
          // 1.He can challenge anyone up to 2 places above him on the league points table.but he cant challenge same team
          //if above two belongs to same team we are enbling above above two other than 

          var above1IsSameTeam = false;
          var above2IsSameTeam = false;

          if (userIndex > 0) {
            var above1User = this.users[userIndex - 1];
            if (above1User != undefined) {
              if (above1User.teamName == userTeamName) {
                above1IsSameTeam = true
              }

            }

            var above2User = this.users[userIndex - 2];
            if (above2User != undefined) {
              if (above2User.teamName == userTeamName) {
                above2IsSameTeam = true
              }

            }
            if (above1IsSameTeam == true && above2IsSameTeam == true) {
              if (index == userIndex - 3 || index == userIndex - 4) {
                isButtonDisable = false
              }
            }
            if (above1IsSameTeam == true && above2IsSameTeam == false) {
              if (index == userIndex - 2 || index == userIndex - 3) {
                isButtonDisable = false
              }
            }
            if (above1IsSameTeam == false && above2IsSameTeam == true) {
              if (index == userIndex - 1 || index == userIndex - 3) {
                isButtonDisable = false
              }
            }
            if (above1IsSameTeam == false && above2IsSameTeam == false) {
              if (index == userIndex - 1 || index == userIndex - 2) {
                isButtonDisable = false
              }
            }




          }




          // 2.Each player will get a maximum of 10 challenges for the entire year. 
          var noOfChallengesForDoneInThisSeason = this.preChallengDetails.noOfChallengesForDoneInThisSeason;
          if (noOfChallengesForDoneInThisSeason >= 10) {
            isButtonDisable = true;
          }

          //3. A player CANNOT challenge his own team mate

          if (rowUser.userId != this.encryptDecryptService.getLocalStorage("userId")) {
            if (rowUser.teamName == userTeamName) {
              isButtonDisable = true;
            }
          }


          //4.A player can make only 1 Challenge per race weekend.
          if (this.preChallengDetails.isChallengedForThisrace == true) {
            isButtonDisable = true;
          }
        }
        // }
      }
    }
    return isButtonDisable;
  }

  challengePerson(user) {
    if (this.isRaceEntryDone == false) {
      $("#addRaceEntryModal").modal("show");
    }
    else {
      this.selectedUser = user;
      $("#exampleModalCenter").modal("show");

    }

  }

  saveChallenge() {
    this.PreLoaderForSave = true
    var payload = {
      "challengeId": 0,
      "isPart1": this.isPart1,
      "challenger": {
        "f1ApplicationUserId": this.encryptDecryptService.getLocalStorage("userId"),
      },
      "challengedPlayer": {
        "f1ApplicationUserId": this.selectedUser.userId
      },
      "race": {
        "raceId": this.upcomingRaceId
      }
    }

    this._genericService.post(this.saveChallengeUrl, payload).subscribe(response => {
      if (response.status.success == "Success") {
        $("#exampleModalCenter").modal("hide");
        this.challegeForRace();
        this.getPreChallengedata();
        this.getChallangesByUserAndSeason();
        this.alreadyChallenged = true;
        this.PreLoaderForSave = false
      }
      else {
        this.PreLoaderForSave = false
      }
    })
  }

  getChallangesForUserAndSeason() {
    this.PreLoader3 = true
    this._genericService.getAll(this.challengesForUser + "?userId=" + this.userId).subscribe(response => {
      if (response.status.success == "Success") {
        this.challengesForUserList = response.data;

        if (response.data == null) {
          this.showMessage1 = true;
        }
        else {
          this.showMessage1 = false;
        }
        this.PreLoader3 = false
      }
      else {
        this.showMessage1 = true;
        this.PreLoader3 = false
      }

    }, error => {
      this.showMessage1 = true;
      this.PreLoader3 = false
    })
  }

  showIndividualProfile(userId) {
    this.userProfileId = userId;
    // Desktop
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '-' + $('.actual-page-view').height() + 'px');
      $(".actual-page-view").css('padding-bottom', '150px');
      $('.main-container').addClass('overflow-hidden');
      $('.single-user-detail-view').removeClass('d-none');
      setTimeout(function () {
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').addClass('d-none');
      $('.single-user-detail-view').removeClass('d-none');
    }
  }

  // Hide Single User Detail View
  closeSingleUserDetailView() {
    // this.userProfileId=0;
    // Desktop
    if ($(window).width() > 1200) {
      $(".actual-page-view").css('margin-top', '0px');
      $(".actual-page-view").css('padding-bottom', '0px');
      $('.main-container').addClass('overflow-hidden');
      setTimeout(function () {
        $('.single-user-detail-view').addClass('d-none');
        $('.main-container').removeClass('overflow-hidden');
      }, 300);
    }
    if ($(window).width() < 1200) {
      $('.actual-page-view').removeClass('d-none');
      $('.single-user-detail-view').addClass('d-none');
    }

  }

  revokeChallenge() {
    this.PreLoaderForSave = true
    this._genericService.delete(this.revokeChallengeUrl + '?challengeId=' + this.revokeChallengeId).subscribe(response => {
      if (response.status.success == "Success") {
        this.revokeUserId = 0;
        $("#revokeChallengeModal").modal("hide");
        this.challegeForRace();
        this.getPreChallengedata();
        this.getChallangesByUserAndSeason();
        this.alreadyChallenged = false;
        this.PreLoaderForSave = false;
      }
      else {
        this.PreLoaderForSave = false
      }
    }, error => {
      this.PreLoaderForSave = false;
    })
  }
  openRevokeChallengeModal(user) {
    this.selectedUser = user;
    $("#revokeChallengeModal").modal("show");
  }


}
