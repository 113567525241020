<!-- Alert Messages -->
<!-- <div *ngIf="raceEntryFailureMsg!=undefined||raceEntryFailureMsg!=null"
  class="alert alert-icon alert-danger fade show slideInRight animated faster" role="alert">
  <div class="gif-icon">
    <img src="../../../../assets/img/failure.gif" alt="success-icon" />
  </div>
  {{raceEntryFailureMsg}}
</div> -->
<div onscroll="headerHaveBg()" class="row h-100 m-0">
  <div class="col-xl-6 col-lg-12">
    <div id="race-entry" class="card shadow-sm rounded race-entry race-entry-page pb-0">
      <div class="card-title bg-blue">
        <h4 *ngIf="!adminRaceResult" class="m-0">Your Race Entry</h4>
        <h4 *ngIf="adminRaceResult" class="m-0">Race Result</h4>

        <ul class="list-unstyled card-title-details" *ngIf="existingRaceEntryDetail!=undefined">
          <li><span>Last updated On :</span> {{existingRaceEntryDetail.updatedOnString|date:'medium'}}</li>
        </ul>
      </div>
      <div class="card-body p-0"*ngIf=" upcomingRaceId!=0 else noRace">
        <div class="row race-entry-details h-100 m-0">
          <div class="col-lg-12 p-2">
            <div class="row m-0 h-100" *ngIf="PreLoader!=true">
              <div *ngIf="resultNotYetUpdated==true">
                <div class="row add-entry align-items-center justify-content-center">
                  <div class="col-lg-12">
                    <p>Race Entry Result not yet Updated,stay tuned for updates...</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-12" *ngIf="resultNotYetUpdated==false" id="myDIV">
                <h5 class="mb-3" *ngIf="circuitDetail!=undefined ||circuitDetail!=null ">{{circuitDetail.name}}
                  -{{circuitDetail.circuits.name}},
                  {{circuitDetail.circuits.location}},{{circuitDetail.circuits.country}}</h5>
                <ul class="list-unstyled race-entry-list mb-3 driversRowStyle">
                  <li>
                    <h5>Drivers</h5>
                  </li>
                  <li *ngFor="let driverobj of selectedDrivers; let i=index">
                    <!-- if No edit -->
                    <div class="race-entry-ranking" *ngIf="showEditButton==false">
                      <div class="ranking">
                        <span class="position">{{getposition(driverobj.position)}}</span>
                        <span class="picture">
                          <img *ngIf="driverobj.selected" src={{driverobj.drivers.driverPic}} alt="driver" />
                          <i *ngIf="!driverobj.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{driverobj.drivers.foreName!=undefined?driverobj.drivers.foreName+'
                          '+driverobj.drivers.surName: " "}}</span>
                        <span *ngIf="!driverobj.selected">Driver{{driverobj.position}}</span>
                      </div>
                    </div>
                    <!-- if edit btn available...  -->
                    <div class="race-entry-ranking" *ngIf="showEditButton==true">
                      <div class="ranking" (click)="
                      addscss();
                      scrollRaceInfo();
                      showConstructorList=false;
                      showDriverList=true;
                      showRaceInfo=false;
                      showDriverListForPolePosition=false;
                      showDriverListForMostPosition=false;
                      showDriverListForFastestLap=false;                      
                      targetPosition=driverobj;">
                        <span class="position">{{getposition(driverobj.position)}}</span>
                        <span class="picture">
                          <i *ngIf="driverobj.selected && showEditButton==true" class="fa fa-times pull-right cross"
                            aria-hidden="true" (click)="removeFromDriverList(driverobj)"></i>
                          <img *ngIf="driverobj.selected" src={{driverobj.drivers.driverPic}} alt="driver" />
                          <i *ngIf="!driverobj.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{driverobj.drivers.foreName!=undefined?driverobj.drivers.foreName+'
                          '+driverobj.drivers.surName: " "}}</span>
                        <span *ngIf="!driverobj.selected">Driver{{driverobj.position}}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="list-unstyled race-entry-list">
                  <li>
                    <h5>Constructors</h5>
                  </li>
                  <li *ngFor="let constructorobj of selectedConstructors; let i=index">
                    <!-- if edit btn not available -->
                    <div class="race-entry-ranking" *ngIf="showEditButton==false">
                      <div class="ranking">
                        <span class="position">{{getposition(constructorobj.position)}}</span>
                        <span class="picture">
                          <img *ngIf="constructorobj.selected " src={{constructorobj.constructors.constructorPic}}
                            alt="driver" />
                          <i *ngIf="!constructorobj.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{constructorobj.constructors.name}}</span>
                        <span *ngIf="!constructorobj.selected">Constructor{{constructorobj.position}}</span>
                      </div>
                    </div>

                    <!-- if  edit btn available -->
                    <div class="race-entry-ranking" *ngIf="showEditButton==true">
                      <div class="ranking" (click)="
                      addscss();
                      scrollRaceInfo();
                      showConstructorList=true; 
                      showDriverList=false;
                      showRaceInfo=false;
                      showDriverListForPolePosition=false;
                      showDriverListForMostPosition=false;
                      showDriverListForFastestLap=false;
                      targetConstructorPosition=constructorobj">
                        <span class="position">{{getposition(constructorobj.position)}}</span>
                        <span class="picture">
                          <i *ngIf="constructorobj.selected " class="fa fa-times pull-right cross" aria-hidden="true"
                            (click)="removeFromConstructorList(constructorobj)"></i>
                          <img *ngIf="constructorobj.selected " src={{constructorobj.constructors.constructorPic}}
                            alt="driver" />
                          <i *ngIf="!constructorobj.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{constructorobj.constructors.name}}</span>
                        <span *ngIf="!constructorobj.selected">Constructor{{constructorobj.position}}</span>
                      </div>
                    </div>
                  </li>
                </ul>
                <ul class="list-unstyled race-entry-list third-row">
                  <li *ngIf="!!selectedFastestLap">
                    <!-- if no edit btn -->
                    <div class="race-entry-ranking lap" *ngIf="showEditButton==false">
                      <div class="position">Fastest Lap </div>
                      <div class="ranking" style="padding-left:33px;">
                        <span class="picture">
                          <img *ngIf="selectedFastestLap.selected && selectedFastestLap.driverPic!=null"
                            src={{selectedFastestLap.driverPic}} alt="driver" />
                          <i *ngIf="!selectedFastestLap.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{selectedFastestLap.foreName||selectedFastestLap.surName!=undefined?selectedFastestLap.foreName
                          +" "+selectedFastestLap.surName:" "}}</span>
                      </div>
                    </div>
                    <!-- if  edit btn available-->
                    <div class="race-entry-ranking lap fastestLapStyle" *ngIf="showEditButton==true">
                      <div class="position">Fastest Lap</div>
                      <div class="ranking" style="padding-left:33px;" (click)="
                        addscss();
                        scrollRaceInfo();
                        showDriverListForFastestLap=true;;
                        showConstructorList=false;
                        showDriverList=false;
                        showRaceInfo=false;
                        showDriverListForPolePosition=false;
                        showDriverListForMostPosition=false;">
                        <span class="picture">
                          <i *ngIf="selectedFastestLap.selected" class="fa fa-times pull-right cross1"
                            aria-hidden="true" (click)="removeFromselectedFastestLap(); "></i>
                          <img *ngIf="selectedFastestLap.selected && selectedFastestLap.driverPic!=null"
                            src={{selectedFastestLap.driverPic}} alt="driver" />
                          <i *ngIf="!selectedFastestLap.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{selectedFastestLap.foreName||selectedFastestLap.surName!=undefined?selectedFastestLap.foreName
                          +" "+selectedFastestLap.surName:" "}}</span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <!-- if no edit btn -->
                    <div class="race-entry-ranking pole" *ngIf="showEditButton==false">
                      <div class="position">Pole position</div>
                      <div class="ranking">
                        <span class="picture">
                          <img *ngIf="selectedPolePosition.selected" src={{selectedPolePosition.driverPic}}
                            alt="driver" />
                          <i *ngIf="!selectedPolePosition.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{selectedPolePosition.foreName||selectedPolePosition.surName!=undefined?selectedPolePosition.foreName
                          +" "+selectedPolePosition.surName:""}}</span>
                      </div>
                    </div>
                    <!-- if  edit btn -->
                    <div class="race-entry-ranking pole" *ngIf="showEditButton==true">
                      <div class="position">Pole position</div>
                      <div class="ranking" (click)="
                      addscss();
                      scrollRaceInfo();
                      showConstructorList=false;
                      showDriverListForFastestLap=false;
                      showDriverList=false;
                      showRaceInfo=false;
                      showDriverListForPolePosition=true;
                      showDriverListForMostPosition=false">
                        <span class="picture">
                          <i *ngIf="selectedPolePosition.selected" class="fa fa-times pull-right cross1"
                            aria-hidden="true" (click)="removeFromselectedpolePosition()"></i>
                          <img *ngIf="selectedPolePosition.selected" src={{selectedPolePosition.driverPic}}
                            alt="driver" />
                          <i *ngIf="!selectedPolePosition.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{selectedPolePosition.foreName||selectedPolePosition.surName!=undefined?selectedPolePosition.foreName
                          +" "+selectedPolePosition.surName:""}}</span>
                      </div>
                    </div>
                  </li>


                  <li *ngIf="adminRaceResult==false">
                    <!-- if no edit btn -->
                    <div class="race-entry-ranking place" *ngIf="showEditButton==false">
                      <div class="position">Most Places Gained</div>
                      <div class="ranking">
                        <span class="picture">
                          <img *ngIf="selectedMostPosition.selected" src={{selectedMostPosition.driverPic}}
                            alt="driver" />
                          <i *ngIf="!selectedMostPosition.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{selectedMostPosition.foreName||selectedMostPosition.surName!=undefined
                          ?selectedMostPosition.foreName +" "+selectedMostPosition.surName:""}}</span>
                      </div>
                    </div>
                    <!-- if edit btn available-->
                    <div class="race-entry-ranking place" *ngIf="showEditButton==true">
                      <div class="position">Most Places Gained</div>
                      <div class="ranking" (click)="
                        addscss();
                        scrollRaceInfo();
                        showDriverListForFastestLap=false;
                        showConstructorList=false;
                        showDriverList=false;
                        showRaceInfo=false;
                        showDriverListForPolePosition=false;
                        showDriverListForMostPosition=true">
                        <span class="picture">
                          <i *ngIf="selectedMostPosition.selected" class="fa fa-times pull-right cross1"
                            aria-hidden="true" (click)="removeFromselectedMostPosition()"></i>
                          <img *ngIf="selectedMostPosition.selected" src={{selectedMostPosition.driverPic}}
                            alt="driver" />
                          <i *ngIf="!selectedMostPosition.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{selectedMostPosition.foreName||selectedMostPosition.surName!=undefined
                          ?selectedMostPosition.foreName +" "+selectedMostPosition.surName:""}}</span>
                      </div>
                    </div>
                  </li>
                 <!-- for admin mpg -->
                  <li  *ngIf="adminRaceResult==true && selectedMpgArray.length==1 ">
                      <!-- if no edit btn -->
                      <div class="race-entry-ranking place" *ngIf="showEditButton==false">
                        <div class="position">Most Places Gained </div>
                        <div class="ranking" *ngFor="let mpg of selectedMpgArray; let i=index">
                          <span class="picture">
                              <img *ngIf="mpg.selected" src={{mpg.mostPlacesGainedDriver.driverPic}}
                              alt="driver" />
                            <i *ngIf="!mpg.selected" class="fa fa-plus"></i>
                          </span>
                          <span class="name">{{mpg.mostPlacesGainedDriver.foreName||mpg.mostPlacesGainedDriver.surName!=undefined
                              ?mpg.mostPlacesGainedDriver.foreName +" "+mpg.mostPlacesGainedDriver.surName:""}}</span>
                        </div>
                      </div>
                      <!-- if edit btn available-->
                      <div class="race-entry-ranking place" *ngIf="showEditButton==true">
                        <div class="position">Most Places Gained</div>
                        <div class="ranking" *ngFor="let mpg of selectedMpgArray; let i=index" (click)="
                          addscss();
                          scrollRaceInfo();
                          showDriverListForFastestLap=false;
                          showConstructorList=false;
                          showDriverList=false;
                          showRaceInfo=false;
                          showDriverListForPolePosition=false;
                          targetMPGPosition=mpg;
                          showDriverListForMostPosition=true">
                          <span class="picture">
                            <i *ngIf="mpg.selected" class="fa fa-times pull-right cross1"
                              aria-hidden="true" (click)="removeFromMPGList(mpg)"></i>
                            <img *ngIf="mpg.selected" src={{mpg.mostPlacesGainedDriver.driverPic}}
                              alt="driver" />
                            <i *ngIf="!mpg.selected" class="fa fa-plus"></i>
                          </span>
                          <span class="name">{{mpg.mostPlacesGainedDriver.foreName||mpg.mostPlacesGainedDriver.surName!=undefined
                            ?mpg.mostPlacesGainedDriver.foreName +" "+mpg.mostPlacesGainedDriver.surName:""}}</span>
                        </div>
                        <li *ngIf="showAddMPGbtn==true && DriverListForMPG.length>0">
                            <div class="add-more" (click)="addMpg()">                                          
                            <i class="fa fa-plus"></i>      
                            </div>               
                        </li>

                      </div>
                    </li>
                </ul>
                <ul id="mpg"  class="list-unstyled race-entry-list mb-3 flex-wrap" *ngIf="adminRaceResult==true && selectedMpgArray.length > 1 ">
                  <li>
                    <h5>Most Places Gained  </h5> 
                  </li>
                   <li *ngFor="let mpg of selectedMpgArray; let i=index"> 
                      <div class="race-entry-ranking" *ngIf="showEditButton==false">
                      <div class="ranking">
                          <span class="picture">
                              <img *ngIf="mpg.selected" src={{mpg.mostPlacesGainedDriver.driverPic}}
                              alt="driver" />
                            <i *ngIf="!mpg.selected" class="fa fa-plus"></i>
                          </span>
                          <span class="name">{{mpg.mostPlacesGainedDriver.foreName||mpg.mostPlacesGainedDriver.surName!=undefined
                              ?mpg.mostPlacesGainedDriver.foreName +" "+mpg.mostPlacesGainedDriver.surName:""}}</span>
                        </div>
                      </div>
                    <div class="race-entry-ranking" *ngIf="showEditButton==true">
                      <div class="ranking" (click)="
                      addscss();
                      scrollRaceInfo();
                      showDriverListForFastestLap=false;
                      showConstructorList=false;
                      showDriverList=false;
                      showRaceInfo=false;
                      showDriverListForPolePosition=false;
                      targetMPGPosition=mpg;
                      showDriverListForMostPosition=true">
                        <span class="picture">
                            <i *ngIf="mpg.selected" class="fa fa-times pull-right cross1"
                            aria-hidden="true"(click)="removeFromMPGList(mpg)" ></i>
                          <img *ngIf="mpg.selected" src={{mpg.mostPlacesGainedDriver.driverPic}}
                            alt="driver" />
                          <i *ngIf="!mpg.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{mpg.mostPlacesGainedDriver.foreName!=undefined?mpg.mostPlacesGainedDriver.foreName+'
                            '+mpg.mostPlacesGainedDriver.surName: " "}}</span>
                      </div>
                      
                    </div>
                    
                  </li>
                  <li class="rr-plus" *ngIf="showAddMPGbtn==true  && DriverListForMPG.length>0">
                    <div class="add-more" *ngIf="showEditButton==true" (click)="addMpg()">                                          
                    <i class="fa fa-plus"></i>      
                    </div>               
                  </li>

                  <!-- <ul class="list-unstyled race-entry-list mb-3">
                    <li>
                      <h5>Drivers 2</h5>
                    </li>
                    <li *ngFor="let driverobj of selectedDrivers; let i=index"> -->
                      <!-- if No edit -->
                      <!-- <div class="race-entry-ranking" *ngIf="showEditButton==false">
                        <div class="ranking">
                          <span class="position">{{getposition(driverobj.position)}}</span>
                          <span class="picture">
                            <img *ngIf="driverobj.selected" src={{driverobj.drivers.driverPic}} alt="driver" />
                            <i *ngIf="!driverobj.selected" class="fa fa-plus"></i>
                          </span>
                          <span class="name">{{driverobj.drivers.foreName!=undefined?driverobj.drivers.foreName+'
                            '+driverobj.drivers.surName: " "}}</span>
                          <span *ngIf="!driverobj.selected">Driver{{driverobj.position}}</span>
                        </div>
                      </div> -->
                      <!-- if edit btn available...  -->
                      <!-- <div class="race-entry-ranking" *ngIf="showEditButton==true">
                        <div class="ranking" 
                        (click)="
                        addscss();
                        scrollRaceInfo();
                        showConstructorList=false;
                        showDriverList=true;
                        showRaceInfo=false;
                        showDriverListForPolePosition=false;
                        showDriverListForMostPosition=false;
                        showDriverListForFastestLap=false;
                        targetPosition=driverobj;">
                          <span class="position">{{getposition(driverobj.position)}}</span>
                          <span class="picture">
                            <i *ngIf="driverobj.selected && showEditButton==true" class="fa fa-times pull-right cross"
                              aria-hidden="true" (click)="removeFromDriverList(driverobj)"></i>
                            <img *ngIf="driverobj.selected" src={{driverobj.drivers.driverPic}} alt="driver" />
                            <i *ngIf="!driverobj.selected" class="fa fa-plus"></i>
                          </span>
                          <span class="name">{{driverobj.drivers.foreName!=undefined?driverobj.drivers.foreName+'
                            '+driverobj.drivers.surName: " "}}</span>
                          <span *ngIf="!driverobj.selected">Driver{{driverobj.position}}</span>
                        </div>
                      </div> -->

                    <!-- </li>
                  </ul> -->
                </ul>

                <ul class="list-unstyled race-entry-list mb-3" *ngIf="isSprintEntry">
                  <li>
                    <h5>Sprint Entry</h5>
                  </li>
                  <li *ngFor="let driverobj of sprintEntryDrivers; let i=index">
                    <!-- if No edit -->
                    <div class="race-entry-ranking" *ngIf="showEditButton==false">
                      <div class="ranking">
                        <span class="position">{{getposition(driverobj.position)}}</span>
                        <span class="picture">
                          <img *ngIf="driverobj.selected" src={{driverobj.drivers.driverPic}} alt="driver" />
                          <i *ngIf="!driverobj.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{driverobj.drivers.foreName!=undefined?driverobj.drivers.foreName+'
                          '+driverobj.drivers.surName: " "}}</span>
                        <span *ngIf="!driverobj.selected">Driver{{driverobj.position}}</span>
                      </div>
                    </div>
                    <!-- if edit btn available...  -->
                    <div class="race-entry-ranking" *ngIf="showEditButton==true">
                      <div class="ranking" (click)="
                      setSprintEntryDriver();
                      showConstructorList=false;
                      showDriverList=false;
                      showRaceInfo=false;                     
                      showDriverListForPolePosition=false;
                      showDriverListForMostPosition=false;
                      showDriverListForFastestLap=false;
                      targetPosition=driverobj;">
                        <span class="position">{{getposition(driverobj.position)}}</span>
                        <span class="picture">
                          <i *ngIf="driverobj.selected && showEditButton==true" class="fa fa-times pull-right cross"
                            aria-hidden="true" (click)="removeFromSprintEntryDriverList(driverobj)"></i>
                          <img *ngIf="driverobj.selected" src={{driverobj.drivers.driverPic}} alt="driver" />
                          <i *ngIf="!driverobj.selected" class="fa fa-plus"></i>
                        </span>
                        <span class="name">{{driverobj.drivers.foreName!=undefined?driverobj.drivers.foreName+'
                          '+driverobj.drivers.surName: " "}}</span>
                        <span *ngIf="!driverobj.selected">Driver{{driverobj.position}}</span>
                      </div>
                    </div>
                  </li>
                </ul>


                <div class="text-center">
                  <button type="submit" *ngIf="adminRaceResult" (click)="goTop()"
                    class="btn btn-outline-primary mt-4 mb-2 mx-1 mx-sm-2" [routerLink]="'/admin/raceResult'">Cancel </button>
                  <button type="submit" *ngIf="showEditButton==true" [disabled]="enableSaveButton()"
                    class="btn btn-primary mt-4 mb-2 mx-1 mx-sm-2" (click)="save()">save </button>
                </div>

              </div>
            </div>
            <div class="box-loader d-flex d-xl-none" *ngIf="PreLoaderForSave">
              <img src="assets/img/progress.svg" alt="Progress">
            </div>
          </div>
        </div>
        <div class="box-loader" *ngIf="PreLoader">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
        <div class="box-loader d-none d-xl-flex" *ngIf="PreLoaderForSave">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>
      <ng-template #noRace>
        <div class="row add-entry align-items-center justify-content-center">
          <div class="col-lg-12">
            <p>Race entry not yet opened, stay tuned for updates...</p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="col-xl-6 col-lg-12">
    <div id="race-info" class="card shadow-sm rounded race-entry race-entry-page pb-0">
      <div *ngIf="showRaceInfo" class="card-title bg-rose">
        <h4 class="m-0">Race Information</h4>
      </div>
      <div *ngIf="showDriverList || sprintEntryDriversActive" class="card-title bg-rose">
        <h4 class="m-0">Drivers</h4>
        <ul class="list-unstyled card-title-details float-right">
          <li class="open-date pr-0"> <i *ngIf="!adminRaceResult" class="fa fa-times p-1 cursor-pointer"
              aria-hidden="true" (click)="showInfoOnly()"></i></li>
        </ul>
      </div>
      <div *ngIf="showConstructorList" class="card-title bg-rose">
        <h4 class="m-0">Constructors</h4>
        <ul class="list-unstyled card-title-details float-right">
          <li class="open-date pr-0"> <i *ngIf="!adminRaceResult" class="fa fa-times p-1 cursor-pointer"
              aria-hidden="true" (click)="showInfoOnly()"></i></li>
        </ul>
      </div>
      <div *ngIf="showDriverListForFastestLap" class="card-title bg-rose">
        <h4 class="m-0">Drivers For Fastest Lap</h4>
        <ul class="list-unstyled card-title-details float-right">
          <li class="open-date pr-0"> <i *ngIf="!adminRaceResult" class="fa fa-times p-1 cursor-pointer"
              aria-hidden="true" (click)="showInfoOnly()"></i></li>
        </ul>
      </div>
      <div *ngIf="showDriverListForPolePosition" class="card-title bg-rose">
        <h4 class="m-0">Drivers For Pole Postion</h4>
        <ul class="list-unstyled card-title-details float-right">
          <li class="open-date pr-0"> <i *ngIf="!adminRaceResult" class="fa fa-times p-1 cursor-pointer"
              aria-hidden="true" (click)="showInfoOnly()"></i></li>
        </ul>
      </div>
      <div *ngIf="showDriverListForMostPosition" class="card-title bg-rose">
        <h4 class="m-0">Drivers For Most Places Gained</h4>
        <ul class="list-unstyled card-title-details float-right">
          <li class="open-date pr-0"> <i *ngIf="!adminRaceResult" class="fa fa-times p-1 cursor-pointer"
              aria-hidden="true" (click)="showInfoOnly()"></i></li>
        </ul>
      </div>

      <div class="card-body p-0">
        <div class="row race-entry-details h-100 m-0">
          <div *ngIf="showRaceInfo && !showDriverList && !showConstructorList && upcomingRaceId!=0" class="col-lg-12 p-2 race-info">
            <div class="row m-0 h-100" *ngIf="PreLoaderForInfo!=true">
              <div class="col-lg-12">
                <div class="row pb-3 align-items-center">
                  <div *ngIf="circuitSrc!=''" class="col-lg-10 offset-lg-1">
                    <img src={{circuitSrc}} alt="lapMap" class="img-fluid" />
                  </div>
                  <div class="col-lg-12 p-0">
                    <h5 class="text-center">{{raceInfoObj.raceName}}</h5>
                  </div>
                  <div class="col-lg-6 mt-3">
                    <ul class="race-info-list">
                      <li><strong>Circuit</strong><span>{{raceInfoObj.Circuits.name}}</span></li>
                      <li><strong>Location</strong><span>{{raceInfoObj.Circuits.location}}</span> </li>
                      <li><strong>Length</strong><span>{{raceInfoObj.Circuits.length}}</span></li>
                      <li><strong>Race Lap Record</strong><span>{{raceInfoObj.Circuits.lapRecord}}</span></li>
                      <li><strong>Number of Turns</strong><span>{{raceInfoObj.Circuits.turns}}</span></li>
                      <li><strong>Number of DRS Zones</strong><span>{{raceInfoObj.Circuits.drsZone}}</span></li>
                    </ul>
                  </div>
                  <div class="col-lg-6 mt-3">
                    <ul class="race-info-list right">
                      <li><strong>Number of Sectors</strong><span>{{raceInfoObj.Circuits.sectors}}</span></li>
                      <li><strong>Weather</strong><span>{{raceInfoObj.weather}}</span></li>
                      <li><strong>Pole position</strong><span>{{raceInfoObj.polePosition}}</span></li>
                      <li><strong>Fastest lap</strong><span>{{raceInfoObj.fastestLap}}</span></li>
                      <li><strong>First</strong><span>{{raceInfoObj.first}}</span></li>
                      <li><strong>Second</strong><span>{{raceInfoObj.second}}</span></li>
                      <li><strong>Third</strong><span>{{raceInfoObj.third}}</span></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <div class="col-lg-12 p-2 race-info" *ngIf="upcomingRaceId==0">
              <div class="row add-entry align-items-center justify-content-center">
                  <div class="col-lg-12">
                    <p>   Currently, No information available for the race.</p>
                  </div>
                </div>
            </div>
          <!-- Selectable Drivers -->
          <div *ngIf="showDriverList && !sprintEntryDriversActive" class="col-lg-12 p-2 selectable-drivers slideInRight animated faster">
            <div class="row m-0 h-100">
              <div class="col-lg-12">
                <!-- <h5 class="mb-4">Drivers
                  <i *ngIf="!adminRaceResult"class="fa fa-times pull-right" aria-hidden="true" (click)="showInfoOnly()"></i>
                </h5> -->
                <ul *ngIf="responseDriverList!=undefined ; else defaultMsg"
                  class="list-unstyled race-entry-list selectable mb-3">
                  <li *ngFor="let driver of responseDriverList ; let i=index">
                    <!-- //For Desktop -->
                    <div class="race-entry-ranking">
                      <div (click)="SourceDetail=driver;interchangeSourceTargetDriver();scrollRaceEntry();">
                        <span class="picture">
                          <img src={{driver.driverPic}} alt="driver" />
                        </span>
                        <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                      </div>
                    </div>                    

                    <div class="hover-details"
                      (click)="SourceDetail=driver;interchangeSourceTargetDriver();scrollRaceEntry();">
                      <div class="race-entry-ranking d-none d-lg-block">
                        <div>
                          <span class="picture">
                            <img src={{driver.driverPic}} alt="driver" />
                          </span>
                          <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                        </div>
                      </div>
                      <!-- Status Cont -->
                      <div class="status-cont">
                        <!-- <div class="row">
                          <span class="label">Points</span>
                          <span *ngIf="driver.points!=null else default" class="label-value">{{driver.points}}</span>
                        </div> -->
                        <div class="row" *ngIf="driver.constructorName != null">
                          <span class="label">Constructor</span>
                          <span *ngIf="driver.constructorName!=null else default"
                            class="label-value">{{driver.constructorName}}</span>
                        </div>
                        <div class="row">
                          <span class="label"> Racing since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.joinedDate|
                              date: 'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="row">
                          <span class="label">Racing for current constructor since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.constructorJoinedDate|
                                date: 'dd-MM-yyyy'}}</span>
                        </div>
                      </div>
                    </div>

                  </li>
                </ul>
                <ng-template #defaultMsg>{{noDriver}} </ng-template>
              </div>
            </div>
          </div>


          <!-- sprint entry deriver-->

          <div *ngIf="sprintEntryDriversActive && !showDriverList" class="col-lg-12 p-2 selectable-drivers slideInRight animated faster">
            <div class="row m-0 h-100">
              <div class="col-lg-12">
                <!-- <h5 class="mb-4">Drivers
                  <i *ngIf="!adminRaceResult"class="fa fa-times pull-right" aria-hidden="true" (click)="showInfoOnly()"></i>
                </h5> -->
                <ul *ngIf="sprintEntryUnSelectDriverList!=undefined ; else defaultMsg"
                  class="list-unstyled race-entry-list selectable mb-3">
                  <li *ngFor="let driver of sprintEntryUnSelectDriverList ; let i=index">
                    <!-- //For Desktop -->
                    <div class="race-entry-ranking">
                      <div (click)="SourceDetail=driver;interchangeSourceTargetSprintEntryDriver();scrollRaceEntry();">
                        <span class="picture">
                          <img src={{driver.driverPic}} alt="driver" />
                        </span>
                        <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                      </div>
                    </div>                    

                    <div class="hover-details"
                      (click)="SourceDetail=driver;interchangeSourceTargetSprintEntryDriver();scrollRaceEntry();">
                      <div class="race-entry-ranking d-none d-lg-block">
                        <div>
                          <span class="picture">
                            <img src={{driver.driverPic}} alt="driver" />
                          </span>
                          <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                        </div>
                      </div>
                      <!-- Status Cont -->
                      <div class="status-cont">
                        <!-- <div class="row">
                          <span class="label">Points</span>
                          <span *ngIf="driver.points!=null else default" class="label-value">{{driver.points}}</span>
                        </div> -->
                        <div class="row">
                          <span class="label">Constructor</span>
                          <span *ngIf="driver.constructorName!=null else default"
                            class="label-value">{{driver.constructorName}}</span>
                        </div>
                        <div class="row">
                          <span class="label"> Racing since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.joinedDate|
                              date: 'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="row">
                          <span class="label">Racing for current constructor since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.constructorJoinedDate|
                                date: 'dd-MM-yyyy'}}</span>
                        </div>
                      </div>
                    </div>

                  </li>
                </ul>
                <ng-template #defaultMsg>{{noDriver}} </ng-template>
              </div>
            </div>
          </div>

          

          <!-- Selectable Drivers for fastest lap -->
          <div *ngIf="showDriverListForFastestLap"
            class="col-lg-12 p-2 selectable-drivers slideInRight animated faster">
            <div class="row m-0 h-100">
              <div class="col-lg-12">
                <!-- <h5 class="mb-4">Drivers For Fastest Lap
                  <i *ngIf="!adminRaceResult" class="fa fa-times pull-right" aria-hidden="true" (click)="showInfoOnly()"></i>
                </h5> -->
                <ul *ngIf="DriverListForFastestLap!=undefined ; else defaultMsg"
                  class="list-unstyled race-entry-list selectable mb-3">
                  <li *ngFor="let driver of DriverListForFastestLap ; let i=index">
                    <!-- //for Desktop -->
                    <div class="race-entry-ranking">
                      <div (click)="sourceFastestLap=driver;FastestlapDetail();scrollRaceEntry();">
                        <span class="picture">
                          <img src={{driver.driverPic}} alt="driver" />
                        </span>
                        <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                      </div>
                    </div>                   
                    <!-- Hover Details -->
                    <div class="hover-details" (click)="sourceFastestLap=driver;FastestlapDetail();scrollRaceEntry();">
                      <div class="race-entry-ranking d-none d-lg-block">
                        <div>
                          <span class="picture">
                            <img src={{driver.driverPic}} alt="driver" />
                          </span>
                          <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                        </div>
                      </div>
                      <!-- status-cont -->
                      <div class="status-cont">
                        <!-- <div class="row">
                          <span class="label">Points</span>
                          <span *ngIf="driver.points!=null else default" class="label-value">{{driver.points}}</span>
                        </div> -->
                        <div class="row">
                          <span class="label">Constructor</span>
                          <span *ngIf="driver.constructorName!=null else default"
                            class="label-value">{{driver.constructorName}}</span>
                        </div>
                        <div class="row">
                          <span class="label"> Racing since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.joinedDate|
                                  date: 'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="row">
                          <span class="label">Racing for current constructor since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.constructorJoinedDate|
                                date: 'dd-MM-yyyy'}}</span>
                        </div>
                      </div>
                    </div>                    
                  </li>
                </ul>
                <ng-template #defaultMsg> {{noFastestLapDriver}} </ng-template>
              </div>
            </div>
          </div>
          <!-- Selectable Drivers for polePostion -->
          <div *ngIf="showDriverListForPolePosition"
            class="col-lg-12 p-2 selectable-drivers slideInRight animated faster">
            <div class="row m-0 h-100">
              <div class="col-lg-12">
                <!-- <h5 class="mb-4">Drivers For Pole Postion
                  <i *ngIf="!adminRaceResult" class="fa fa-times pull-right" aria-hidden="true" (click)="showInfoOnly()"></i>
                </h5> -->

                <ul *ngIf="DriverListForPolePosition!=undefined ; else defaultMsg"
                  class="list-unstyled race-entry-list selectable mb-3">
                  <li *ngFor="let driverobj of DriverListForPolePosition ; let i=index">
                    <!-- //For Desktop -->
                    <div class="race-entry-ranking">
                      <div (click)="sourcePolePosition=driverobj;polePositionDetail();scrollRaceEntry();">
                        <span class="picture">
                          <img src={{driverobj.driverPic}} alt="driver" />
                        </span>
                        <span class="name">{{driverobj.foreName +" "+driverobj.surName}}</span>
                      </div>
                    </div>
                    <!-- Hover Details -->
                    <div class="hover-details"
                      (click)="sourcePolePosition=driverobj;polePositionDetail();scrollRaceEntry();">
                      <div class="race-entry-ranking d-none d-lg-block">
                        <div>
                          <span class="picture">
                            <img src={{driverobj.driverPic}} alt="driver" />
                          </span>
                          <span class="name">{{driverobj.foreName +" "+driverobj.surName}}</span>
                        </div>
                      </div>
                      <!-- status-cont -->
                      <div class="status-cont">
                        <!-- <div class="row">
                          <span class="label">Points</span>
                          <span *ngIf="driverobj.points!=null else default"
                            class="label-value">{{driverobj.points}}</span>
                        </div> -->
                        <div class="row">
                          <span class="label">Constructor</span>
                          <span *ngIf="driverobj.constructorName!=null else default"
                            class="label-value">{{driverobj.constructorName}}</span>
                        </div>
                        <div class="row">
                          <span class="label"> Racing since</span>
                          <span *ngIf="driverobj.driverMaster!=null else default" class="label-value">{{driverobj.driverMaster.joinedDate|
                                    date: 'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="row">
                          <span class="label">Racing for current constructor since</span>
                          <span *ngIf="driverobj.driverMaster!=null else default" class="label-value">{{driverobj.driverMaster.constructorJoinedDate|
                                date: 'dd-MM-yyyy'}}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ng-template #defaultMsg> {{noPolePostionDriver}} </ng-template>
              </div>
            </div>
          </div>
          <!-- Selectable Drivers for most postion gained FOR  RACE ENTRY-->
          <div *ngIf="showDriverListForMostPosition && adminRaceResult==false"
            class="col-lg-12 p-2 selectable-drivers slideInRight animated faster">
            <div class="row m-0 h-100">
              <div class="col-lg-12">
                <!-- <h5 class="mb-4">Drivers For Most Places Gained
                  <i *ngIf="!adminRaceResult" class="fa fa-times pull-right" aria-hidden="true" (click)="showInfoOnly()"></i>
                </h5> -->
                <ul *ngIf="DriverListForMostPosition!=undefined ; else defaultMsg"
                  class="list-unstyled race-entry-list selectable mb-3">
                  <li *ngFor="let driver of DriverListForMostPosition ; let i=index">
                    <!-- //For Desktop -->
                    <div class="race-entry-ranking">
                      <div (click)="sourceMostPosition=driver;
                      mostPositionGainedDetail();scrollRaceEntry();">
                        <span class="picture">
                          <img src={{driver.driverPic}} alt="driver" />
                        </span>
                        <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                      </div>
                    </div>
                    <!-- Hover Details -->
                    <div class="hover-details" (click)="sourceMostPosition=driver;
                    mostPositionGainedDetail();scrollRaceEntry();">
                      <div class="race-entry-ranking d-none d-lg-block">
                        <div>
                          <span class="picture">
                            <img src={{driver.driverPic}} alt="driver" />
                          </span>
                          <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                        </div>
                      </div>
                      <!-- status-cont -->
                      <div class="status-cont">
                        <!-- <div class="row">
                          <span class="label">Points</span>
                          <span *ngIf="driver.points!=null else default" class="label-value">{{driver.points}}</span>
                        </div> -->
                        <div class="row">
                          <span class="label">Constructor</span>
                          <span *ngIf="driver.constructorName!=null else default"
                            class="label-value">{{driver.constructorName}}</span>
                        </div>
                        <div class="row">
                          <span class="label"> Racing since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.joinedDate|
                                        date: 'dd-MM-yyyy'}}</span>
                        </div>
                        <div class="row">
                          <span class="label">Racing for current constructor since</span>
                          <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.constructorJoinedDate|
                                date: 'dd-MM-yyyy'}}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ng-template #defaultMsg> {{noMostPlaceGained}} </ng-template>
              </div>
            </div>
          </div>
          <!-- selecte mpg for race result FOR RACE RESULT -->
          <div *ngIf="showDriverListForMostPosition && adminRaceResult==true"
          class="col-lg-12 p-2 selectable-drivers slideInRight animated faster">
          <div class="row m-0 h-100">
            <div class="col-lg-12">
              <ul *ngIf="DriverListForMPG!=undefined ; else defaultMsg"
                class="list-unstyled race-entry-list selectable mb-3">
                <li *ngFor="let driver of DriverListForMPG ; let i=index">
                  <!-- //For Desktop -->
                  <div class="race-entry-ranking">
                    <div (click)="scrollRaceEntry();sourceMostPosition=driver;">
                      <span class="picture">
                        <img src={{driver.driverPic}} alt="driver" />
                      </span>
                      <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                    </div>
                  </div>
                  <!-- Hover Details -->
                  <div class="hover-details" (click)="scrollRaceEntry();sourceMostPosition=driver;interchangeSourceTargetMPG()">
                    <div class="race-entry-ranking d-none d-lg-block">
                      <div>
                        <span class="picture">
                          <img src={{driver.driverPic}} alt="driver" />
                        </span>
                        <span class="name">{{driver.foreName +" "+driver.surName}}</span>
                      </div>
                    </div>
                    <!-- status-cont -->
                    <div class="status-cont">
                      <!-- <div class="row">
                        <span class="label">Points</span>
                        <span *ngIf="driver.points!=null else default" class="label-value">{{driver.points}}</span>
                      </div> -->
                      <div class="row">
                        <span class="label">Constructor</span>
                        <span *ngIf="driver.constructorName!=null else default"
                          class="label-value">{{driver.constructorName}}</span>
                      </div>
                      <div class="row">
                        <span class="label"> Racing since</span>
                        <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.joinedDate|
                                      date: 'dd-MM-yyyy'}}</span>
                      </div>
                      <div class="row">
                        <span class="label">Racing for current constructor since</span>
                        <span *ngIf="driver.driverMaster!=null else default" class="label-value">{{driver.driverMaster.constructorJoinedDate|
                              date: 'dd-MM-yyyy'}}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
              <ng-template #defaultMsg> {{noMostPlaceGained}} </ng-template>
            </div>
          </div>
        </div>
          <!-- Selectable constructors -->
          <div *ngIf="showConstructorList" class="col-lg-12 p-2 selectable-constructors slideInRight animated faster">
            <div class="row m-0 h-100">
              <div class="col-lg-12">
                <!-- <h5 class="mb-4">Constructors
                  <i *ngIf="!adminRaceResult" class="fa fa-times pull-right" aria-hidden="true" (click)="showInfoOnly()"></i>
                </h5> -->
                <ul *ngIf="responseconstructorList!=undefined ; else defaultMsg"
                  class="list-unstyled race-entry-list selectable mb-3">
                  <li *ngFor="let constructorobj of responseconstructorList; let i=index">
                    <!-- //For DeskTop -->
                    <div class="race-entry-ranking">
                      <div
                        (click)="SourceConstructorDetail=constructorobj;interchangeSourceTargetConstructor();scrollRaceEntry();interchangeSourceTargetMGP()">
                        <span class="picture">
                          <img
                            *ngIf="constructorobj.constructorPic!=undefined || constructorobj.constructorPic!=null ;else defaultImg"
                            src={{constructorobj.constructorPic}} alt="driver" />
                          <ng-template #defaultImg><img src="../../../assets/img/1.jpg" alt="driver" /></ng-template>
                        </span>
                        <span class="name">{{constructorobj.name}}</span>
                      </div>
                    </div>

                    <!-- hover details -->
                    <div class="hover-details"
                      (click)="SourceConstructorDetail=constructorobj;interchangeSourceTargetConstructor();scrollRaceEntry();">
                      <div class="race-entry-ranking d-none d-lg-block">
                        <div>
                          <span class="picture">
                            <img
                              *ngIf="constructorobj.constructorPic!=undefined || constructorobj.constructorPic!=null ;else defaultImg"
                              src={{constructorobj.constructorPic}} alt="driver" />
                            <ng-template #defaultImg><img src="../../../assets/img/1.jpg" alt="driver" /></ng-template>
                          </span>
                          <span class="name">{{constructorobj.name}}</span>
                        </div>
                      </div>
                      <!-- status-cont -->
                      <div class="status-cont constructor-status-cont">
                        <!-- <div class="row">
                          <span class="label">Points</span>
                          <span *ngIf="constructorobj.points!=null else defaultCons"
                            class="label-value">{{constructorobj.points}}</span>
                        </div> -->
                        <div class="row">
                          <span class="label">Drivers</span>
                          <span *ngIf="constructorobj.driverName!=null else defaultCons"
                            class="label-value">{{constructorobj.driverName}}</span>
                        </div>
                        <div class="row">
                          <span class="label"> Racing since</span>
                          <span *ngIf="constructorobj.constructorMaster!=null else defaultCons" class="label-value">
                            {{constructorobj.constructorMaster.joinedDate | date: 'dd-MM-yyyy'}}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
                <ng-template #defaultMsg>{{noConstructor}} </ng-template>
              </div>
            </div>
          </div>
        </div>
        <!-- Box loader for Race Information -->
        <div class="box-loader" *ngIf="PreLoaderForInfo">
          <img src="assets/img/progress.svg" alt="Progress">
        </div>
      </div>

    </div>
  </div>
  <!-- Race Entry Accordion -->
  <div class="col-lg-12" *ngIf="!adminRaceResult">
    <app-previous-race-result></app-previous-race-result>
  </div>

</div>